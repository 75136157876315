<template>
  <div class="certificates">
    <Card class="card">
      <h1 class="card-title">{{ $tr('profile.certificates.title') }}</h1>
      <div v-if="!isLoading && !certificates.length" class="card-warning">
        <img src="@/assets/graphics/warning.svg" class="card-item__preview" />
        <p>{{ $tr('profile.certificates.warning') }}</p>
      </div>
      <div v-else class="card-list">
        <ul class="card-list">
          <li
            v-for="(item, index) in certificates"
            :key="index"
            class="card-item"
          >
            <img
              src="@/assets/graphics/document.svg"
              class="card-item__preview"
            />
            <div class="card-item__content">
              <h4 class="card-item__name">{{ item.name }}</h4>
              <!--              <div class="card-status">-->
              <!--                  <span class="card-status__again" v-if="item.status === 3">Ожидает модерации</span>-->
              <!--                  <span class="card-status__new" v-else>Сертификат готов</span>-->
              <!--              </div>-->
              <Button
                type="primary"
                size="small"
                class="card-item__download"
                @click="download(item.link)"
              >
                {{ $tr('document.action.download') }}
              </Button>
              <Button
                v-if="item.status !== 3"
                type="primary"
                size="small"
                class="card-item__again"
                @click="again(item.id)"
              >
                {{ $tr('document.action.recreate') }}
              </Button>
            </div>
          </li>
        </ul>
      </div>
    </Card>
  </div>
</template>

<script>
import useCertificates from '@/domain/composables/use-certificates'
import { BASE_URL } from '@/api/api-instance.js'

export default {
  components: {},
  data() {
    return {
      certificates: null,
      isLoading: false,
    }
  },
  mounted() {
    this.getCertificates()
  },
  methods: {
    async fetchCertificateList() {
      const eventId = this.$store.getters['event/currentEventId']
      const userId = this.$store.state.auth.userId
      this.certificates = await useCertificates().getCertificates(
        eventId,
        userId,
      )

      console.log('++++', this.certificates)
    },

    async getCertificates() {
      this.isLoading = true
      try {
        await this.fetchCertificateList()
      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading = false
      }
    },

    async again(id) {
      const res = await useCertificates().againCertificates(id)

      if (res.data) {
        this.$Message.success(this.$tr('profile.certificates.reissued'))
      } else {
        this.$Message.error(this.$tr('header.titles.error'))
      }

      await this.fetchCertificateList()
    },

    download(link) {
      window
        .open(
          BASE_URL + 'api/v1/certificate/generate/download?link=' + link,
          '_blank',
        )
        .focus()
    },
  },
}
</script>

<style lang="scss">
@import '~@/styles/pages/certificates.scss';
</style>
