import i18n from '@/plugins/i18n'
import { getExistingApiInstance } from '@/api'

export default function useCertificates() {
  const statusNew = 0
  const statusAgain = 3

  async function getCertificates(eventId, userId) {
    const api = getExistingApiInstance()
    const params = {
      filter: {
        entityId: eventId,
        userId: userId,
        status: [statusNew, statusAgain],
        'template.lang': i18n.locale,
      },
    }
    const { data } = await api.certificates.getMany(params)

    return data
  }

  async function againCertificates(id) {
    const api = getExistingApiInstance()
    const attr = {
      file_path: null,
    }
    const { data } = await api.certificates.update(id, attr)

    return data
  }

  return {
    getCertificates,
    againCertificates,
  }
}
